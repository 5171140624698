<div *ngIf="post$ | async as post; else loading">
<div class="post">
  <div class="header">
    <h1>{{post.title}}</h1>

    <button routerLink="/" class="btn btn-link">На главную</button>
  </div>

  <div class="info">
    <strong>{{post.author}}</strong>
    <small>{{ post.date | date:'dd MMMM yyyy, HH:mm:ss':null:'ru' }}</small>
  </div>

  <div>
   <quill-view-html [content]="post.text"></quill-view-html>
  </div>
</div>
</div>

<ng-template #loading>
  <p class="text-center">Загрузка</p>
</ng-template>
