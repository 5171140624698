<div class="card">
  <div class="class-header">
    <h2>{{post.title}}</h2>

    <small>{{post.date | date:'dd.MM.yyyy'}}</small>
  </div>

  <div>
    <small>{{post.author}}</small>
  </div>

  <button [routerLink]="['/post', post.id]" class="btn btn-dark">Открыть</button>
</div>
